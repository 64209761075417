@charset "utf-8";

$body-line-height: 2;

$logo-pink: (#EBADD1, #FFF, #EBCCDE, #D49DBD);
$logo-pink-light: (nth($logo-pink, 3), #FFF, #000, nth($logo-pink, 1));
$logo-pink-dark: (nth($logo-pink, 4), #FFF, nth($logo-pink, 1), #BD8CA8);

$logo-orange: (#D68f5C, #FFF, #D6AE92, #BF8052);
$logo-orange-light: (nth($logo-orange, 3), #FFF, #000, nth($logo-orange, 1));
$logo-orange-dark: (nth($logo-orange, 4), #FFF, nth($logo-orange, 1), #A87048);

$logo-gold: (#EBDAAD, rgba(0,0,0,0.7), #EBE2CC, #D9CAA0);
$logo-gold-light: (nth($logo-gold, 3), #FFF, #000, nth($logo-gold, 1));
$logo-gold-dark: (nth($logo-gold, 4), #FFF, nth($logo-gold, 1), #CCBE97);

$custom-colors: (
"logo-gold": $logo-gold,
"logo-gold-light": $logo-gold-light,
"logo-gold-dark": $logo-gold-dark,
"logo-pink": $logo-pink,
"logo-pink-light": $logo-pink-light,
"logo-pink-dark": $logo-pink-dark,
"logo-orange": $logo-orange,
"logo-orange-light": $logo-orange-light,
"logo-orange-dark": $logo-orange-dark
);

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";

@import "../../node_modules/bulma/sass/components/card.sass";
@import "../../node_modules/bulma/sass/components/dropdown.sass";
@import "../../node_modules/bulma/sass/components/level.sass";
@import "../../node_modules/bulma/sass/components/navbar.sass";
@import "../../node_modules/bulma/sass/components/pagination.sass";
@import "../../node_modules/bulma/sass/components/panel.sass";
@import "../../node_modules/bulma/sass/components/tabs.sass";

@import "../../node_modules/bulma/sass/elements/box.sass";
@import "../../node_modules/bulma/sass/elements/button.sass";
@import "../../node_modules/bulma/sass/elements/container.sass";
@import "../../node_modules/bulma/sass/elements/content.sass";
@import "../../node_modules/bulma/sass/elements/icon.sass";
@import "../../node_modules/bulma/sass/elements/image.sass";
@import "../../node_modules/bulma/sass/elements/other.sass";
@import "../../node_modules/bulma/sass/elements/tag.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";

@import "../../node_modules/bulma/sass/form/shared.sass";
@import "../../node_modules/bulma/sass/form/input-textarea.sass";
@import "../../node_modules/bulma/sass/form/checkbox-radio.sass";
@import "../../node_modules/bulma/sass/form/select.sass";
@import "../../node_modules/bulma/sass/form/tools.sass";

@import "../../node_modules/bulma/sass/grid/_all.sass";

@import "../../node_modules/bulma/sass/layout/hero.sass";
@import "../../node_modules/bulma/sass/layout/section.sass";
@import "../../node_modules/bulma/sass/layout/footer.sass";

@import "../../node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass";
@import "../../node_modules/ionicons/dist/collection/components/icon/icon";

.facebook.icon {
    fill: #3B5998;
}

.icon.youtube {
    fill: red;
}

.instagram.icon {
  padding: 3px;
  fill: white;
  border-radius: 11px;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

